import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import routes from 'routing/routes'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

import CourseTracker from 'pages/course_tracker'
import DeleteAccount from 'pages/delete-account'
import EventsHome from 'pages/events'
import Home from 'pages/home'
import Lockers from 'pages/lockers'
import SUPacksWrapper, { SUPacks } from 'pages/pack-registration'
import SUPacksSubcategory from 'pages/pack-registration/category/items'
import SUPacksCategory from 'pages/pack-registration/category/subcategories'
import SUPacksCheckout from 'pages/pack-registration/checkout'
import SUPacksItem from 'pages/pack-registration/item'
import RateAUCPage from 'pages/rate-auc'
import AddReviewPage from 'pages/rate-auc/components/add-review'
import CoursePage from 'pages/rate-auc/components/course'
import ProfessorPage from 'pages/rate-auc/components/professor'
import RecruitmentForm from 'pages/recruitment/form'
import StudyNotesHome from 'pages/study-notes'
import CourseList from 'pages/study-notes/[category]'
import NoteList from 'pages/study-notes/[course]'
import CategoryList from 'pages/study-notes/CategoryList'
import UploadNote from 'pages/study-notes/UploadNote'
import SUCardHome from 'pages/su-card'
import SUCardCategory from 'pages/su-card/CategoryPage'
import Training from 'pages/training'

const BrowserRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: routes.HOME, element: <Home />, index: true },
      { path: routes.EVENTS, element: <EventsHome /> },
      { path: routes.LOCKERS, element: <Lockers /> },
      { path: routes.RECRUITMENT_FORM, element: <RecruitmentForm /> },
      {
        path: routes.SU_CARD,
        children: [
          {
            index: true,
            element: <SUCardHome />,
          },

          {
            path: 'category/:id',
            element: <SUCardCategory />,
          },
        ],
      },
      // Uncomment the following block to enable SU Packs
      // {
      //   path: routes.SU_PACK,
      //   element: <SUPacksWrapper />,
      //   children: [
      //     {
      //       index: true,
      //       element: <SUPacks />,
      //     },
      //     {
      //       path: 'categories',
      //       children: [
      //         {
      //           path: ':category',
      //           children: [
      //             {
      //               element: <SUPacksCategory />,
      //               index: true,
      //             },
      //             {
      //               path: ':subCategory',
      //               element: <SUPacksSubcategory />,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       path: 'items',
      //       children: [
      //         {
      //           path: ':id',
      //           element: <SUPacksItem />,
      //         },
      //       ],
      //     },
      //     {
      //       path: 'checkout',
      //       element: <SUPacksCheckout />,
      //     },
      //     {
      //       path: 'rate-auc',
      //       element: <RateAUCPage />,
      //     },
      //   ],
      // },
      { path: routes.STUDY_NOTES, element: <StudyNotesHome /> },
      { path: routes.COURSE_TRACKER, element: <CourseTracker /> },
      { path: routes.CATEGORY_LIST, element: <CategoryList /> },
      { path: routes.COURSE_LIST, element: <CourseList /> },
      { path: routes.NOTE_LIST, element: <NoteList /> },
      { path: routes.UPLOAD_NOTE, element: <UploadNote /> },
      { path: routes.DELETE_ACCOUNT, element: <DeleteAccount /> },
      {
        path: routes.RATE_PROFESSORS,
        children: [
          {
            index: true,
            element: <RateAUCPage />,
          },
          {
            path: 'professors/:id',
            element: <ProfessorPage />,
          },
          {
            path: 'courses/:id',
            element: <CoursePage />,
          },
          {
            path: 'add-review',
            element: <AddReviewPage />,
          },
        ],
      },
      // { path: routes.TRAINING, element: <Training /> },
      { path: '*', element: <h1>404</h1> },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <RouterProvider router={BrowserRouter} />

  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
