import {
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import { createRating, getProfessor, vote } from 'api/rate-auc.service'
import Loading from 'components/common/loading'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ArrowBack, ArrowUpward, ExpandLess } from '@mui/icons-material'
import Rating from '../rating'
import moment from 'moment'
import { useAuth } from 'context'
import ProfessorSearch from '../professor-search'
import CourseSearch from '../course-search'
import RatingInput from '../rating-input'
import DifficultyInput from '../difficulty-input'
import LogIn from 'pages/study-notes/login'

const AddReviewPage = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const id = searchParams.get('professor')
  const navigate = useNavigate()
  const { user } = useAuth()

  // const id = useSearchParams().get('id')
  const [data, setData] = useState({
    professor: id,
    course: null,
    rating: 0,
    difficulty: 0,
    comment: '',
    isAnonymous: false,
    department: null,
  })

  // const [data, setData] = useState(null)
  // const [error, setError] = useState(null)
  // const [loading, setLoading] = useState(true)

  // const fetchData = async () => {
  //   getProfessor(id)
  //     .then(d => {
  //       setData(d)
  //     })
  //     .catch(() => {
  //       setError('Oops. An error occurred while fetching the data.')
  //     })
  //     .finally(() => setLoading(false))
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [id])

  if (!user) return <LogIn />

  return (
    <Box
      sx={{
        minHeight: '80vh',
        maxWidth: 'xl',
        padding: '0 2rem',
        mx: 'auto',
      }}
    >
      {isLoading && <Loading />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: '4rem',
          mb: '2rem',
        }}
      >
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Link
            to={'/rate-professors'}
            style={{ textDecoration: 'none', display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          >
            <ArrowBack />
            Back
          </Link>

          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 500,
            }}
          >
            Rate a Professor!
          </Typography>
        </Box>
      </Box>
      <Box
        component="form"
        onSubmit={async e => {
          e.preventDefault()
          // handle form submission
          const { professor, course, rating, difficulty, comment, isAnonymous } = data
          try {
            setIsLoading(true)
            await createRating(
              parseInt(professor),
              course,
              rating,
              difficulty,
              isAnonymous,
              comment
            )
            setIsLoading(false)
            toast.success('Review submitted successfully!')
            navigate(`/rate-professors/professors/${professor}`, { replace: true })
          } catch (e) {
            toast.error(
              e.response?.data?.message ||
                'An error occurred while submitting the review (honestly, I have no idea what happened)'
            )

            setIsLoading(false)
          }
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
          First, Select your professor
        </Typography>
        <ProfessorSearch
          onChange={(value, department) => {
            setData({ ...data, professor: value, department })
          }}
          value={data.professor}
        />
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
          Now, Select the course you took with{' '}
          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 500,
              display: 'inline',

              // rainbow text
              // background:
              //   'linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722)',
              // '-webkit-background-clip': 'text',
              // '-webkit-text-fill-color': 'transparent',
            }}
          >
            them
          </Typography>
        </Typography>
        <CourseSearch
          onChange={value => {
            setData({ ...data, course: value })
          }}
          disabled={!!!data.professor}
          professor={data.professor}
        />
        <Box>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>Rate the professor</Typography>
          <Typography sx={{ fontSize: '1', fontWeight: 400, opacity: 0.7 }}>
            Rate the professor on a scale of 1 to 5, 1 being{' '}
            <Typography color="red" sx={{ fontWeight: 800, display: 'inline' }}>
              avoid awy
            </Typography>{' '}
            and 5 being{' '}
            <Typography color="green" sx={{ fontWeight: 800, display: 'inline' }}>
              salek awy
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <RatingInput
            rating={data.rating}
            onChange={value => {
              setData({ ...data, rating: value })
            }}
          />
          <Typography sx={{ fontSize: '1rem', fontWeight: 500 }}>({data.rating})</Typography>
        </Box>

        <Box>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
            Rate the difficulty of the course
          </Typography>
          <Typography sx={{ fontSize: '1', fontWeight: 400, opacity: 0.7 }}>
            This is independent of the professor. Rate the difficulty of the course on a scale of 1
            to 5
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <DifficultyInput
            difficulty={data.difficulty}
            onChange={value => {
              setData({ ...data, difficulty: value })
            }}
          />
          <Typography sx={{ fontSize: '1rem', fontWeight: 500 }}>({data.difficulty})</Typography>
        </Box>

        <Box>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
            Leave a comment{' '}
            <Typography sx={{ fontWeight: 400, display: 'inline', fontSize: '1rem' }}>
              (optional)
            </Typography>
          </Typography>
          <Typography sx={{ fontSize: '1', fontWeight: 400, opacity: 0.7, mb: '1rem' }}>
            Leave a comment to help others know more about your experience.
          </Typography>

          <TextField
            label="Comment"
            multiline
            rows={4}
            fullWidth
            value={data.comment}
            onChange={e => {
              setData({ ...data, comment: e.target.value })
            }}
          />
        </Box>
        <Box>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>Anonymous?</Typography>
          <Typography sx={{ fontSize: '1', fontWeight: 400, opacity: 0.7, mb: '1rem' }}>
            Do you want to post this review anonymously?
          </Typography>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Chip
              label="Yes"
              color={data.isAnonymous ? 'primary' : 'default'}
              onClick={() => setData({ ...data, isAnonymous: true })}
              sx={{ color: data.isAnonymous ? 'white' : 'black', padding: '0.5rem 1rem' }}
            />
            <Chip
              label="No"
              color={!data.isAnonymous ? 'primary' : 'default'}
              onClick={() => setData({ ...data, isAnonymous: false })}
              sx={{ color: data.isAnonymous ? 'black' : 'white', padding: '0.5rem 1rem' }}
            />
          </Box>
        </Box>
        <Box sx={{ my: '2rem' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: '0.5rem 2rem',
              fontSize: '1.2rem',
              textTransform: 'none',
              color: 'white',
            }}
            type="submit"
          >
            Submit Review
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AddReviewPage
