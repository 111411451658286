import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  Typography,
} from '@mui/material'
import { getCourse } from 'api/rate-auc.service'
import Loading from 'components/common/loading'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import Rating from '../rating'
import Difficulty from '../difficulty'
import LogIn from 'pages/study-notes/login'
import { useAuth } from 'context'

const BASE_IMAGE_URL = process.env.REACT_APP_PROFESSORS_BUCKET_BASE_URL
// const imageColors = ['#0D8ABC', '#2abc0d', '#bc4d0d', '#2d0dbc']
const imageColors = ['#0D8ABC']

const CoursePage = () => {
  const id = useParams().id
  const { user } = useAuth()

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    getCourse(id)
      .then(d => {
        setData(d)
      })
      .catch(() => {
        setError('Oops. An error occurred while fetching the data.')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [id])

  if (!user) return <LogIn />

  if (error) {
    return (
      <Box
        sx={{
          minHeight: '80vh',
          maxWidth: 'xl',
          padding: '0 2rem',
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            my: '4rem',
          }}
        >
          <Typography
            sx={{
              fontSize: '2.5rem',
              fontWeight: 'bold',
            }}
          >
            {error}
          </Typography>
          <Link to={'/rate-professors'} style={{ textDecoration: 'none' }}>
            <Button>Home</Button>
          </Link>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '80vh',
        maxWidth: 'xl',
        padding: '0 2rem',
        mx: 'auto',
      }}
    >
      {loading && <Loading />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: '4rem',
        }}
      >
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Link
            to={'/rate-professors'}
            style={{ textDecoration: 'none', display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          >
            <ArrowBack />
            Back
          </Link>

          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 500,
            }}
          >
            Course Details
          </Typography>
        </Box>
        {data && (
          <>
            <Typography
              sx={{
                fontSize: '2.5rem',
                fontWeight: 'bold',
              }}
            >
              {data.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              <Chip
                sx={{
                  fontSize: '1rem',
                  opacity: 0.7,
                  mb: '1rem',
                  width: 'fit-content',
                }}
                color="success"
                variant="outlined"
                label={data.department}
              />
              <Chip
                sx={{
                  fontSize: '1rem',
                  opacity: 0.7,
                  mb: '1rem',
                  width: 'fit-content',
                }}
                color="warning"
                variant="outlined"
                label={data.course_code}
              />
              <Chip
                sx={{
                  fontSize: '1rem',
                  opacity: 0.7,
                  mb: '1rem',
                  width: 'fit-content',
                }}
                color="error"
                variant="outlined"
                label={`Reviews: ${data.review_count}`}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 500,
                }}
              >
                Average Difficulty
              </Typography>
              <Difficulty difficulty={data.avgDiff || 0} id={'avg'} />
            </Box>
          </>
        )}
      </Box>
      <Divider sx={{ my: '2rem' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: '2rem',
            fontWeight: 600,
          }}
        >
          Top Professors
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          flexWrap: 'wrap',
          my: '2rem',
          justifyContent: {
            xs: 'center',
            md: 'flex-start',
          },
        }}
      >
        {data &&
          data.professors
            .sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0))
            .map((professor, index) => (
              <Link
                to={`/rate-professors/professors/${professor.id}`}
                style={{ textDecoration: 'none' }}
              >
                <Card sx={{ width: 400, height: 110 }} key={professor.id}>
                  <CardActionArea sx={{ height: '100%' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <img
                          height="140"
                          src={
                            professor.img_id
                              ? `${BASE_IMAGE_URL}${professor.img_id}.jpg`
                              : `https://ui-avatars.com/api/?name=${
                                  professor.name
                                }&background=${imageColors[
                                  Math.floor(Math.random() * imageColors.length)
                                ].substring(1)}&color=fff&size=300`
                          }
                          alt="professor image"
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '100%',
                            objectFit: 'cover',
                          }}
                        />

                        <Box>
                          <Typography
                            sx={{
                              fontSize: '1.2rem',
                              fontWeight: 600,
                              textOverflow: 'ellipsis',
                              width: 300,
                            }}
                            noWrap
                            component="div"
                          >
                            {professor.name}
                          </Typography>

                          {professor.review_count ? (
                            <Rating
                              rating={professor.averageRating || 0}
                              id={professor.id}
                              size={20}
                            />
                          ) : (
                            <Typography sx={{ fontSize: '1rem', opacity: 0.7 }}>
                              No reviews yet
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            ))}
      </Box>
    </Box>
  )
}

export default CoursePage
