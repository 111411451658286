import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Chip, Divider, Typography } from '@mui/material'
import { getProfessor } from 'api/rate-auc.service'
import Loading from 'components/common/loading'
import { useAuth } from 'context'
import LogIn from 'pages/study-notes/login'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Rating from '../rating'
import { Ratings } from '../ratings'

const BASE_IMAGE_URL = process.env.REACT_APP_PROFESSORS_BUCKET_BASE_URL
// const imageColors = ['#0D8ABC', '#2abc0d', '#bc4d0d', '#2d0dbc']
const imageColors = ['#0D8ABC']

const ProfessorPage = () => {
  const id = useParams().id
  const { user } = useAuth()

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    getProfessor(id)
      .then(d => {
        setData(d)
      })
      .catch(() => {
        setError('Oops. An error occurred while fetching the data.')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [id])

  if (!user) return <LogIn />

  if (error) {
    return (
      <Box
        sx={{
          minHeight: '80vh',
          maxWidth: 'xl',
          padding: '0 2rem',
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            my: '4rem',
          }}
        >
          <Typography
            sx={{
              fontSize: '2.5rem',
              fontWeight: 'bold',
            }}
          >
            {error}
          </Typography>
          <Link to={'/rate-professors'} style={{ textDecoration: 'none' }}>
            <Button>Home</Button>
          </Link>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '80vh',
        maxWidth: 'xl',
        padding: '0 2rem',
        mx: 'auto',
      }}
    >
      {loading && <Loading />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: '4rem',
          mb: '2rem',
        }}
      >
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Link
            to={'/rate-professors'}
            style={{ textDecoration: 'none', display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          >
            <ArrowBack />
            Back
          </Link>

          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 500,
            }}
          >
            Professor Profile
          </Typography>
        </Box>
        {data && (
          <>
            <img
              src={
                data.img_id
                  ? BASE_IMAGE_URL + `${data.img_id}.jpg`
                  : `https://ui-avatars.com/api/?name=${data.name}&background=${imageColors[
                      Math.floor(Math.random() * imageColors.length)
                    ].substring(1)}&color=fff&size=300`
              }
              alt="professor"
              style={{
                borderRadius: '50%',
                width: '150px',
                height: '150px',
                margin: '1rem',
                objectFit: 'cover',
              }}
            />
            <Typography
              sx={{
                fontSize: '2.5rem',
                fontWeight: 'bold',
              }}
            >
              {data.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', mb: '1rem' }}>
              <Chip
                sx={{
                  fontSize: '1rem',
                  opacity: 0.7,
                  width: 'fit-content',
                }}
                color="success"
                variant="outlined"
                label={data.department}
              />
              <Chip
                sx={{
                  fontSize: '1rem',
                  opacity: 0.7,
                  width: 'fit-content',
                }}
                color="error"
                variant="outlined"
                label={`Reviews: ${data.ratings.length}`}
              />
              {data.courses.map(course => (
                <Link
                  to={`/rate-professors/courses/${course.id}`}
                  key={course.id}
                  style={{ textDecoration: 'none', height: 'fit-content' }}
                >
                  <Chip
                    sx={{
                      fontSize: '1rem',
                      opacity: 0.7,
                      width: 'fit-content',
                    }}
                    color="primary"
                    variant="outlined"
                    label={course.name}
                  />
                </Link>
              ))}
            </Box>

            <Rating rating={data.avgRating || 0} id={'avg'} />
          </>
        )}
      </Box>
      <Divider sx={{ mb: '2rem' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: '2rem',
            fontWeight: 600,
          }}
        >
          Reviews
        </Typography>
        <Link
          to={`/rate-professors/add-review?professor=${data ? data.id : ''}`}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained" sx={{ color: 'white' }}>
            Leave a Review
          </Button>
        </Link>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {data && data.ratings.length === 0 && (
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 500,
              opacity: 0.7,
            }}
          >
            No reviews yet
          </Typography>
        )}
        {data && data.ratings.length > 0 && (
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 500,
              opacity: 0.7,
              mb: '1rem',
            }}
          >
            {data.ratings.length} reviews
          </Typography>
        )}
        <Ratings ratings={data ? data.ratings : []} onVote={fetchData} />
      </Box>
    </Box>
  )
}

export default ProfessorPage
