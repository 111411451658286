import React from 'react'
import { Badge, badgeClasses, Button, Divider, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import logo from 'assets/SU-LOGOwhite-400.png'
import vpPic from 'assets/vp-pic.webp'
import presidentPic from 'assets/pres-pic.webp'
import Header from 'components/common/header'
import { ArrowCircleLeftOutlined, ArrowCircleRightOutlined } from '@mui/icons-material'

import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const RateBadge = styled(Badge)`
  & .${badgeClasses.badge} {
    top: -20px;
    right: -20px;
    color: white;
  }
`

const Home = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {/* first section */}
      <Box
        sx={{
          backgroundColor: 'neutral.dark',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          minHeight: '80vh',
        }}
      >
        <Box
          sx={{
            color: '#cccccc',
            p: { xs: 3, sm: 10 },
            mt: { xs: 0, md: -5, xl: -10 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: {
              xs: 'center',
              md: 'flex-start',
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              flex: 0.3,
              fontSize: { xs: 42, md: 72 },
              mb: 1,
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            AUC{' '}
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: 50, md: 88 },
                display: 'inline',
                color: '#fff',
                // color: '#00adff',
              }}
            >
              S
            </Typography>
            TUDENT{' '}
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: 50, md: 88 },
                display: 'inline',
                color: '#fff',
                // color: '#00adff',
              }}
            >
              U
            </Typography>
            NION
          </Typography>
          <Typography
            sx={{
              mb: { xs: 5 },
              fontSize: { xs: '14px', md: '20px', sm: '16px' },
              maxWidth: { xs: '100%', md: '80%' },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            The student union is an entity for the students by the students. It works on three
            mandates:{' '}
            <Typography
              sx={{
                display: 'inline',
                fontWeight: '700',
                fontSize: { xs: '14px', md: '20px', sm: '16px' },
              }}
              variant="h4"
            >
              Serve, Represent, and Entertain
            </Typography>{' '}
            the student body.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Link to={'/rate-professors'}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  padding: '10px 20px',
                  width: '240px',
                  position: 'relative',
                }}
              >
                Rate AUC Professors!
                <RateBadge badgeContent={'New'} color="secondary" overlap="circular" />
              </Button>
            </Link>
            <a href="https://www.facebook.com/AUC.SU.REP" target="_blank" rel="noreferrer">
              <Button
                variant="outlined"
                color="tertiary"
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  padding: '10px 20px',
                  width: '200px',
                }}
              >
                Contact us
              </Button>
            </a>
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            display: 'flex',
            mb: { sm: 5, md: 0 },
            mt: { sm: -10, md: 0 },
          }}
        >
          <img src={logo} alt="logo" height={400} width={400} />
        </Box>
      </Box>
      <Divider />
      {/* <Section
        sx={{
          pt: 4,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#f7faff',
        }}
      >
        <Header title="Latest News" />

        <Box
          sx={{
            flexDirection: { xs: 'row', md: 'row' },
            display: 'flex',
            flex: 1,
            width: '100%',
            maxHeight: '80%',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            maxWidth: '50%',
            minHeight: '45vh',
          }}
        >
          <Box
            sx={{
              borderRadius: '50px',
              display: 'flex',
              border: '1px solid #c9c9c9',
              flex: 1,
              width: '100%',
              mb: 10,
              alignItems: 'center',
              // boxShadow: '2px 3px px 0 #9e9e9e',
              height: { xs: '60%', sm: '80%' },
              justifyContent: 'center',
              maxWidth: '65%',
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: { xs: 25, sm: 36 },
                px: 10,
              }}
              variant="h3"
            >
              Stay Tuned
            </Typography>
          </Box>
        </Box>
      </Section> */}
      <Divider />
      <Box sx={{ backgroundColor: '#eff1f7', pt: 4 }}>
        <Header title="Presedential Office" />
        <Box
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            display: 'flex',
            px: 10,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '45vh',
            paddingTop: 3,
            paddingBottom: 5,
          }}
        >
          {[
            {
              name: 'Ahmed Khatib',
              position: 'President',
              pic: presidentPic,
            },
            {
              name: 'Farida Sarhank',
              position: 'Vice President',
              pic: vpPic,
            },
          ].map(item => (
            <Box
              key={item.name}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: { xs: 3, md: 0 },
                mx: { xs: 0, md: 4 },
              }}
            >
              <Box
                sx={{
                  borderRadius: '10%',
                  border: '1px solid rgba(0,0,0,0.3)',
                  boxShadow: '2px 3px 10px 0 rgba(0,0,0,0.2)',
                  overflow: 'hidden',
                  mx: 4,
                  height: { xs: '200px', md: '280px' },
                  width: { xs: '200px', md: '280px' },
                  transition: 'all 0.14s ease-out',
                  ':hover': {
                    boxShadow: '2px 3px 10px 0 rgba(0,0,0,0.3)',
                    transform: 'translateY(-5px)',
                  },
                }}
              >
                <img
                  src={item.pic}
                  alt="logo"
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
              <Typography component="strong" mt={1} fontWeight={700} fontSize={24}>
                {item.name}
              </Typography>
              <Typography component="strong" sx={{ opacity: 0.8 }}>
                {item.position}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}

export default Home
