import { Box } from '@mui/material'
import { Star } from '@mui/icons-material'

export default function Rating({ rating, size = 40, gap = '1rem', id }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap,
      }}
    >
      {/* The yellow stars */}
      {Array.from({ length: Math.ceil(rating) }).map((_, i) => (
        <Box
          key={i}
          sx={{
            fontSize: '2rem',
            position: 'relative',
          }}
        >
          {i !== Math.floor(rating) ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width={size}
              height={size}
              viewBox="0 0 32 32"
            >
              <defs>
                <linearGradient id={`grad-gold-${i}`}>
                  <stop offset="20%" stopColor={i !== Math.floor(rating) ? '#f0c260' : '#313131'} />
                  <stop offset="40%" stopColor={i !== Math.floor(rating) ? '#f0c260' : '#313131'} />
                </linearGradient>
              </defs>
              <path
                fill={`url(#grad-gold-${i})`}
                d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
l11.547-1.2L16.026,0.6L20.388,10.918z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width={size}
              height={size}
              viewBox="0 0 32 32"
            >
              <defs>
                <linearGradient id={`grad-gold-${i}-${id}`}>
                  <stop offset={`${(rating % 1) * 100}%`} stopColor={'#f0c260'} />
                  <stop offset={`${(rating % 1) * 100}%`} stopColor={'#313131'} />
                </linearGradient>
              </defs>
              <path
                fill={`url(#grad-gold-${i}-${id})`}
                d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
l11.547-1.2L16.026,0.6L20.388,10.918z"
              />
            </svg>
          )}
        </Box>
      ))}

      {/* The grey stars */}
      {Array.from({ length: 5 - Math.ceil(rating) }).map((_, i) => (
        <Box
          key={i}
          sx={{
            color: '#313131',
            fontSize: '2rem',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            viewBox="0 0 32 32"
          >
            <defs>
              <linearGradient id={`grad-${i}`}>
                <stop offset="20%" stopColor="#313131" />
                <stop offset="40%" stopColor="#313131" />
              </linearGradient>
            </defs>
            <path
              fill={`url(#grad-${i})`}
              d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
l11.547-1.2L16.026,0.6L20.388,10.918z"
            />
          </svg>
        </Box>
      ))}
    </Box>
  )
}
