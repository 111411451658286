import { Box } from '@mui/material'
import { Star } from '@mui/icons-material'

export default function RatingInput({ rating, size = 40, gap = '1rem', onChange }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap,
      }}
    >
      {/* The yellow stars */}
      {Array.from({ length: Math.ceil(rating) }).map((_, i) => (
        <Box
          key={i}
          sx={{
            fontSize: '2rem',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (onChange) {
              onChange(i + 1)
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            viewBox="0 0 32 32"
          >
            <defs>
              <linearGradient id={`grad-gold-${i}`}>
                <stop offset="20%" stopColor={'#f0c260'} />
                <stop offset="40%" stopColor={'#f0c260'} />
              </linearGradient>
            </defs>
            <path
              fill={`url(#grad-gold-${i})`}
              d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
l11.547-1.2L16.026,0.6L20.388,10.918z"
            />
          </svg>
        </Box>
      ))}

      {/* The grey stars */}
      {Array.from({ length: 5 - Math.ceil(rating) }).map((_, i) => (
        <Box
          key={i}
          sx={{
            fontSize: '2rem',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (onChange) {
              onChange(i + Math.ceil(rating) + 1)
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            viewBox="0 0 32 32"
          >
            <defs>
              <linearGradient id={`grad-${i}`}>
                <stop offset="20%" stopColor="#313131" />
                <stop offset="40%" stopColor="#313131" />
              </linearGradient>
            </defs>
            <path
              fill={`url(#grad-${i})`}
              d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
l11.547-1.2L16.026,0.6L20.388,10.918z"
            />
          </svg>
        </Box>
      ))}
    </Box>
  )
}
