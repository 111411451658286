import './App.css'
import { Box } from '@mui/system'
import Topbar from 'components/layout/topbar'
import { Outlet } from 'react-router-dom'
import AppProvider from './context'
import Footer from 'components/layout/footer'

function App() {
  // console.log('HEEE')
  return (
    <AppProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
        id="mins-app"
      >
        <Topbar />
        <Box component="main" sx={{ flexGrow: 1 }} mt={10}>
          {/* <Box sx={{ p: 3 }}> */}
          <Outlet />
          {/* </Box> */}
        </Box>
        <Footer />
      </Box>
    </AppProvider>
  )
}

export default App
