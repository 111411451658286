import { Box, Typography } from '@mui/material'

export default function OneDifficulty({ difficulty, size = 40 }) {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontSize: '0.8rem',
          fontWeight: 500,
          opacity: 0.7,
          whiteSpace: 'nowrap',
          marginRight: '0.5rem',
          marginTop: '0.2rem',
        }}
      >
        Course Difficulty:
      </Typography>
      <img
        src={`/difficulties/${difficulty}.png`}
        alt={`expression ${difficulty}`}
        style={{
          width: size,
          height: size,
        }}
      />
    </Box>
  )
}
