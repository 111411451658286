import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.neutral.light,
  '&.active': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    color: theme.palette.primary.dark,
  },
  fontSize: '1rem',
  transition: 'color 0.2s',
  fontWeight: 500,
  letterSpacing: '1px',
  padding: '0 1rem',
  position: 'relative',
  color: '#e3f0ff',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100%',
  textDecoration: 'none',
  cursor: 'pointer',
}))
export default StyledNavLink
