import { Edit } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, Divider, Typography } from '@mui/material'
import { getLatestRatings, getTotalCount } from 'api/rate-auc.service'
import Loading from 'components/common/loading'
import { useAuth } from 'context'
import LogIn from 'pages/study-notes/login'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery, useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import CourseSearch from './components/course-search'
import ProfessorSearch from './components/professor-search'
import { Ratings } from './components/ratings'

const RateAUCPage = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [ref, inView] = useInView()
  const [sortBy, setSortBy] = useLocalStorage('sort-by', 'newest')

  const { data: totalCount, isLoading: isLoadingTotalCount } = useQuery(
    ['countRatings'],
    getTotalCount,
    {
      enabled: !!user,
    }
  )

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['infiniteScrollRateAUC', sortBy],
      queryFn(c) {
        return getLatestRatings(sortBy, c.pageParam, 20)
      },
      getNextPageParam(lastPage) {
        return lastPage.nextCursor
      },
      enabled: !!user,
    })

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [inView])

  if (!user) return <LogIn />

  return (
    <Box
      sx={{
        minHeight: '80vh',
        maxWidth: 'xl',
        padding: '0 2rem',
        mx: 'auto',
      }}
    >
      {isLoading && <Loading />}
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          my: '4rem',
        }}
      >
        <Typography
          sx={{
            fontSize: '2.5rem',
            fontWeight: 'bold',
          }}
        >
          Rate AUC Professors
        </Typography>
        <Link to="/rate-professors/add-review">
          <Button
            variant="contained"
            color="primary"
            style={{
              color: 'white',
              fontSize: '1.3rem',
              padding: '0.5rem 1.5rem',
              textTransform: 'none',
              marginTop: '1rem',
              marginBottom: '0.5rem',
            }}
            startIcon={<Edit />}
          >
            Rate a professor!
          </Button>
        </Link>
        <p className="call-to-action-description">
          Click here to give a rating for a professor on a certain course
        </p>
      </Box>

      {/* Search Container */}
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: {
                xs: 'center',
                md: 'flex-start',
              },
            }}
          >
            <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Search by professor</Typography>
            <Typography sx={{ opacity: 0.7, mb: 2 }}>
              Search by professor to view their ratings
            </Typography>
            <ProfessorSearch
              onChange={value => {
                if (value) navigate(`/rate-professors/professors/${value}`)
              }}
            />
          </Box>
          <Typography
            sx={{
              margin: {
                xs: '1rem 0',
                md: 'auto',
                fontWeight: 600,
              },
            }}
          >
            OR
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: {
                xs: 'center',
                md: 'flex-start',
              },
            }}
          >
            <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Search by course</Typography>
            <Typography sx={{ opacity: 0.7, mb: 2 }}>
              Search by professor to view their ratings
            </Typography>
            <CourseSearch
              onChange={value => {
                navigate(`/rate-professors/courses/${value}`)
              }}
            />
          </Box>
        </Box>
      </Box>

      <Divider sx={{ my: '2rem' }} />

      <Box>
        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>
          Check out the Latest Reviews!
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem', mb: '1rem' }}>
          <Chip
            label="Newest"
            color={sortBy === 'newest' ? 'primary' : 'default'}
            onClick={() => setSortBy('newest')}
            sx={{ color: sortBy !== 'newest' ? 'black' : 'white', padding: '0.6rem 1.2rem' }}
          />
          <Chip
            label="Popular"
            color={sortBy === 'popular' ? 'primary' : 'default'}
            onClick={() => setSortBy('popular')}
            sx={{ color: sortBy !== 'popular' ? 'black' : 'white', padding: '0.6rem 1.2rem' }}
          />
        </Box>

        {data?.pages.map((page, i) => (
          <Ratings key={i} ratings={page.ratings} onVote={() => refetch({})} />
        ))}

        {isFetchingNextPage && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: '2rem',
              gap: 2,
            }}
          >
            <CircularProgress />
            <Typography sx={{ textAlign: 'center' }}>Loading...</Typography>
          </Box>
        )}
        {!hasNextPage && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: '2rem',
              gap: 2,
              opacity: 0.7,
            }}
          >
            <Typography sx={{ textAlign: 'center' }}>
              Get a Life Bro! You read all {isLoadingTotalCount ? 'loading...' : totalCount}{' '}
              reviews! That's crazy.
            </Typography>
          </Box>
        )}
        <div
          ref={ref}
          style={{
            width: '100%',
            height: '50px',
          }}
        />
      </Box>
    </Box>
  )
}

export default RateAUCPage
