import { Box } from '@mui/material'

export default function DifficultyInput({ difficulty, size = 40, gap = '1rem', onChange }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap,
      }}
    >
      {/* Render facial expression images */}
      {Array.from({ length: 5 }).map((_, i) => (
        <Box
          key={i}
          sx={{
            width: size,
            height: size,
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (onChange) {
              onChange(i + 1)
            }
          }}
        >
          {/* Full image (dimmed if outside difficulty) */}
          <img
            src={`/difficulties/${i + 1}.png`}
            alt={`expression ${i + 1}`}
            style={{
              width: size,
              height: size,
              opacity: i < Math.floor(difficulty) ? 1 : 0.3, // Dim if beyond difficulty
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />

          {/* Overlay partially filled image for fractional difficulty */}
          {i === Math.floor(difficulty) && difficulty % 1 > 0 && (
            <img
              src={`/difficulties/${i + 1}.png`}
              alt={`partial expression ${i + 1}`}
              style={{
                width: size,
                height: size,
                position: 'absolute',
                top: 0,
                left: 0,
                clipPath: `inset(0 ${100 - (difficulty % 1) * 100}% 0 0)`, // Clip the image to fill fractionally
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  )
}
