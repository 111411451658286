import { Edit } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { getSUCardCategories, getSUCardVendors } from 'api/card.service'
import Loading from 'components/common/loading'
import { useAuth } from 'context'
import useApi from 'hooks/useApi'
import LogIn from 'pages/study-notes/login'
import { useQueries, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import SUCard from './components/suCard'

const SUCardHome = () => {
  const { user } = useAuth()
  const { data: categories, isLoading } = useQuery({ queryFn: getSUCardCategories })
  if (!user) return <LogIn />
  const suCardName = user.given_name + ' ' + user.family_name
  if (isLoading) return <Loading></Loading>

  return (
    <>
      <Box
        sx={{
          minHeight: '80vh',
          maxWidth: 'xl',
          padding: '0 2rem',
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            my: '4rem',
          }}
        >
          <Typography
            sx={{
              fontSize: '2.5rem',
              fontWeight: 'bold',
            }}
          >
            Your SU Card
          </Typography>
          <p className="call-to-action-description">
            SU card enables you to get special discounts and offers from our partners.
          </p>

          <SUCard
            userName={user.given_name && user.family_name ? suCardName : user.email}
            Id={user.studentId ? user.studentId : '900******'}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '1rem',
            marginBottom: '2rem',
          }}
        >
          {categories &&
            categories.map(category => (
              <Link to={`/su-card/category/${category.id}`} key={category.id}>
                <Box
                  key={category.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { xs: '140px', sm: '140px', md: '200px' },

                    height: { xs: '140px', sm: '140px', md: '200px' },
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    padding: '1rem',
                    textAlign: 'center',
                    color: '#000',
                    ':hover': { backgroundColor: '#f5f5f5' },
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <img style={{ marginBottom: '1rem' }} src={category.primaryIcon} />
                  <Typography
                    sx={{
                      fontSize: { sm: '1rem', md: '1.5rem' },
                      fontWeight: 'bold',
                    }}
                  >
                    {category.category}
                  </Typography>
                </Box>
              </Link>
            ))}
        </Box>
      </Box>
    </>
  )
}

export default SUCardHome
