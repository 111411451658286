import routes from 'routing/routes'

const navlinkItems = [
  { name: 'Home', to: routes.HOME, index: true, end: true },
  //{ name: 'Recruitment', to: routes.RECRUITMENT_FORM },
  // { name: 'Events', to: routes.EVENTS },

  // Uncomment the following block to enable SU Packs
  // { name: 'SU Packs', to: routes.SU_PACK },
  { name: 'Lockers', to: routes.LOCKERS },
  // { name: 'Rep Report', to: routes.REP_REPORT },
  { name: 'Course Tracker', to: routes.COURSE_TRACKER },
  { name: 'Study Notes', to: routes.STUDY_NOTES },
  { name: 'Rate AUC Professors', to: routes.RATE_PROFESSORS },
  { name: 'SU Card', to: routes.SU_CARD },
  // { name: 'Training', to: routes.TRAINING },
]

export default navlinkItems
