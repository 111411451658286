import { ExpandLess } from '@mui/icons-material'
import { Box, Button, Chip, Divider, Typography } from '@mui/material'
import { vote } from 'api/rate-auc.service'
import moment from 'moment'
import { Link } from 'react-router-dom'
import OneDifficulty from './one-difficulty'
import Rating from './rating'
import React, { memo } from 'react'

const RatingItem = memo(({ rating, onVote, includeProfessorName }) => (
  <Box
    key={rating.id}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <img
          src={rating.user_picture}
          alt="user"
          style={{ borderRadius: '50%', width: '50px', height: '50px' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: { xs: 0, md: '1rem' },
              marginBottom: '-0.5rem',
              alignItems: {
                xs: 'flex-start',
                md: 'center',
              },
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: '1rem',
                  md: '1.2rem',
                },
                margin: 0,
                fontWeight: 500,
              }}
            >
              {rating.user_name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '0.25rem',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Link
                to={`/rate-professors/professors/${rating.professor_id}`}
                sx={{
                  paddingTop: {
                    xs: 0,
                    md: '0.5rem',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 400,
                    opacity: 0.7,
                    color: 'black',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {rating.professor_name}
                </Typography>
              </Link>
              {includeProfessorName && (
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 400,
                    opacity: 0.7,
                    color: 'black',
                    display: {
                      xs: 'none',
                      md: 'block',
                    },
                  }}
                >
                  -
                </Typography>
              )}
              <Link
                to={`/rate-professors/courses/${rating.course_id}`}
                sx={{
                  paddingTop: {
                    xs: 0,
                    md: '0.5rem',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 400,
                    opacity: 0.7,
                    color: 'black',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {rating.course_name}
                </Typography>
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Rating size={20} gap={'0.1rem'} rating={rating.rating} id={rating.id} />
            <OneDifficulty difficulty={rating.course_difficulty || 0} size={20} />
          </Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 500,
              opacity: 0.7,
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            {rating.comment}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: {
            xs: 'flex-start',
            md: 'flex-end',
          },
        }}
      >
        <Chip
          sx={{
            fontSize: '0.8rem',
            opacity: 0.7,
            mb: '1rem',
            width: 'fit-content',
          }}
          color="error"
          variant="outlined"
          label={moment(rating.created_at).format('MMM DD, YYYY hh:mm A')}
        />
        {/* Upvotes and Downvotes */}
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          <Button
            endIcon={<ExpandLess />}
            title="Upvote"
            color="success"
            sx={{
              gap: 0,
              color: rating.user_vote === 1 ? 'white' : 'black',
            }}
            variant={rating.user_vote === 1 ? 'contained' : 'text'}
            onClick={async () => {
              await vote(rating.id, rating.user_vote === 1 ? 0 : 1)
              onVote(rating.id, rating.user_vote === 1 ? 0 : 1)
            }}
          >
            {rating.likes}
          </Button>
          <Button
            endIcon={
              <ExpandLess
                style={{
                  transform: 'rotate(180deg)',
                }}
              />
            }
            title="Downvote"
            color="error"
            variant={rating.user_vote === -1 ? 'contained' : 'text'}
            sx={{ gap: 0, color: rating.user_vote === -1 ? 'white' : 'black' }}
            onClick={async () => {
              await vote(rating.id, rating.user_vote === -1 ? 0 : -1)
              onVote(rating.id, rating.user_vote === -1 ? 0 : -1)
            }}
          >
            {rating.dislikes}
          </Button>
        </Box>
      </Box>
    </Box>
    <Typography
      sx={{
        fontSize: '1rem',
        fontWeight: 500,
        opacity: 0.7,
        display: {
          xs: 'block',
          md: 'none',
        },
      }}
    >
      {rating.comment}
    </Typography>
    <Divider />
  </Box>
))

export const Ratings = memo(({ onVote, ratings, includeProfessorName = false }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', mb: '2rem' }}>
      {ratings &&
        ratings.map((rating, idx) => (
          <RatingItem
            key={rating.id}
            rating={rating}
            onVote={onVote}
            includeProfessorName={includeProfessorName}
          />
        ))}
    </Box>
  )
})
