import http from './http'

const endPoint = '/lockers/'

export const getLockers = async () => {
  const res = await http.get(endPoint)
  console.log(res)
  return res
}

export const postLocker = async ({ location }) => {
  const res = await http.post(endPoint, {
    location,
  })
  return res
}

export const checkLocker = async () => {
  const res = await http.post(endPoint + 'check/', {})

  return res
}
