// import { baseUrls } from "../config/api.json";
// const suCardBaseUrl = "https://api.aucsu.org/api/v1/cards/frontend";
import http from './http'

const cardsEndpoint = '/cards'

export const getSUCardCategories = async () => {
  const res = await http.get(`${cardsEndpoint}/categories`)
  return res.data
}
export const getSUCardVendorsByCategory = async id => {
  const res = await http.get(`${cardsEndpoint}/vendors`)
  return res.data.filter(vendor => vendor.categoryID == id)
}
export const getSUCardVendors = async () => {
  return await http
    .get(cardsEndpoint + '/vendors')
    .then(res => res.data)
    .catch(err => {
      throw err.response.data
    })
}

export const getSUCardEnabled = async () => {
  return await http
    .get(cardsEndpoint + '/enabled')
    .then(res => res.data)
    .catch(err => {
      throw err.response.data
    })
}
