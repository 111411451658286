import http from './http'

const endPoint = '/rateauc'

export const getLatestRatings = async (mode = 'newest', pageCursor, pageSize = 20) => {
  const res = await http.get(`${endPoint}/ratings?`, {
    params: { mode, pageSize, ...(pageCursor ? { pageCursor } : {}) },
  })

  return res.data.data
}

export const getTotalCount = async () => {
  const res = await http.get(`${endPoint}/count`)

  return res.data.data
}

export const getProfessors = async () => {
  const res = await http.get(`${endPoint}/professors`)

  return res.data.data
}

export const getCourses = async () => {
  const res = await http.get(`${endPoint}/courses`)

  return res.data.data
}

export const getCoursesByProfessor = async professor => {
  const res = await http.get(`${endPoint}/professor/${professor}`)

  return res.data.data
}

export const getProfessor = async id => {
  const res = await http.get(`${endPoint}/ratings/professor/${id}`)

  return res.data.data
}

export const getCourse = async id => {
  const res = await http.get(`${endPoint}/course/${id}`)

  return res.data.data
}

export const vote = async (id, value) => {
  const res = await http.post(`${endPoint}/vote/${id}`, { value })

  return res.data.data
}

export const createRating = async (
  professorId,
  courseId,
  rating,
  difficulty,
  anonymous,
  comment
) => {
  const res = await http.post(`${endPoint}/rate`, {
    professorID: professorId,
    courseID: courseId,
    rating,
    difficulty,
    isAnonymous: anonymous,
    comment,
  })

  return res.data.data
}
