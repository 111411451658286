import { Box, Typography } from '@mui/material'
import { APIProvider, Map } from '@vis.gl/react-google-maps'
import { getSUCardVendorsByCategory } from 'api/card.service'
import Loading from 'components/common/loading'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { AccordionList } from './components/VendorAccordion'
import { CustomAdvancedMarker } from './components/VendorMarker'

const SUCardCategory = () => {
  const id = useParams().id
  const { data, isLoading } = useQuery({
    queryFn: () => getSUCardVendorsByCategory(id),
    queryKey: ['getSUCardVendorsByCategory', { id }],
  })
  if (isLoading) return <Loading />
  if (!data) return <div> No data </div>
  if (data.length === 0) return <div> category Not Found! </div>

  const filteredData = data.filter(vendor => vendor.latitude && vendor.longitude)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        my: '4rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
        }}
      >
        <Typography
          sx={{
            fontSize: '2.5rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            textAlign: 'left',
            width: '100%',
          }}
        >
          {data[0].category}
        </Typography>
        {filteredData.length !== 0 && (
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '400px',
                width: '100%',
                mx: 'auto',
              }}
            >
              <Map
                style={{ position: 'relative' }}
                defaultZoom={10}
                defaultCenter={{ lat: 30.0181, lng: 31.5031 }}
                mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
                disableDefaultUI
              >
                {filteredData.map(vendor => (
                  <CustomAdvancedMarker key={vendor.vendorName} vendor={vendor} />
                ))}
              </Map>
            </Box>
          </APIProvider>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '80%',
        }}
      >
        <AccordionList data={data} />
      </Box>
    </Box>
  )
}

export default SUCardCategory
