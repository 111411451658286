import { FormatItalic } from '@mui/icons-material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PublicIcon from '@mui/icons-material/Public'
import QrCodeIcon from '@mui/icons-material/QrCode'
import StarIcon from '@mui/icons-material/Star'
import { Box, Card, CardContent, Typography } from '@mui/material'
import logo from 'assets/SU-LOGOwhite-small.png'
import { motion } from 'framer-motion'
import React from 'react'

const SUCard = ({ userName, Id }) => {
  return (
    <Card
      sx={{
        width: { xs: 300, md: 400 },
        height: { xs: 180, md: 240 },
        background: 'linear-gradient(135deg, #000428, #004e92)',
        color: 'white',
        borderRadius: 3,
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
        paddingX: 2,
        paddingBottom: 1,
        marginTop: 2,
        position: 'relative',
        overflow: 'hidden',
        ':hover': { transform: 'scale(1.05)' },
        transition: ' all 0.3s ease',
      }}
    >
      {/* Title and Icons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontSize: { md: 20 }, fontWeight: 'bold' }}>
          SU CARD
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <StarIcon fontSize="small" />
          <PublicIcon fontSize="small" />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* QR & Currency */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: 'auto',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 18, md: 22 },
              letterSpacing: 2,
              fontFamily: 'Birthstone',
              lineHeight: 1,
            }}
          >
            {userName}
          </Typography>

          <Typography
            sx={{ fontSize: { xs: 14, md: 16 }, letterSpacing: 2, fontWeight: '300', margin: 0 }}
          >
            {Id}
          </Typography>
        </Box>
        <motion.div
          initial={{ left: '-100%' }}
          animate={{ left: '100%' }}
          transition={{ duration: 3, repeat: Infinity }}
          style={{
            position: 'absolute',
            top: 0,
            left: '-100%',
            width: '60%',
            height: '100%',
            background:
              'linear-gradient(to right, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 80%)',
            transform: 'skewX(-20deg)',
          }}
        />
        <img src={logo} alt="" width={60} />
      </Box>
    </Card>
  )
}

export default SUCard
