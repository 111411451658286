import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { getCourses, getCoursesByProfessor } from 'api/rate-auc.service'
import { useNavigate } from 'react-router-dom'

export default function CourseSearch({ onChange, disabled = false, professor = null }) {
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (professor) {
        setOptions(await getCoursesByProfessor(professor))
      } else {
        setOptions(await getCourses())
      }
      setLoading(false)
    })()
  }, [professor])

  return (
    <Autocomplete
      sx={{ width: 300 }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={option => `${option.name}`}
      options={options}
      loading={loading}
      onChange={(event, value) => {
        if (onChange) {
          onChange(value?.id)
        }
      }}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          label="Search by Course..."
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
    />
  )
}
