import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { getProfessors } from 'api/rate-auc.service'

export default function ProfessorSearch({ onChange, value }) {
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [goodLookingValue, setGoodLookingValue] = React.useState({
    id: value,
    name: '',
    department: '',
  })

  React.useEffect(() => {
    if (options.length === 0) return
    const option = options.find(option => option.id.toString() === value)
    setGoodLookingValue(option)
    onChange(option?.id?.toString(), option?.department)
  }, [value, options])

  React.useEffect(() => {
    ;(async () => {
      setLoading(true)
      setOptions(await getProfessors())
      setLoading(false)
    })()
  }, [])

  if (loading) return <CircularProgress />

  return (
    <Autocomplete
      sx={{ width: 300 }}
      isOptionEqualToValue={(option, v) => option.id === v?.id}
      getOptionLabel={option => `${option.name} (${option.department})`}
      options={options}
      loading={loading}
      value={goodLookingValue}
      onChange={(event, value) => {
        if (onChange) {
          onChange(value?.id?.toString(), value?.department)
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Search by Professor..."
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name} ({option.department})
        </li>
      )}
    />
  )
}
