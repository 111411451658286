import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { Element } from 'react-scroll'

export function AccordionList({ data }) {
  return (
    <div style={{ width: '100%', marginTop: '2rem' }}>
      {data.map((vendor, i) => (
        <VendorAccordion key={vendor.id} data={vendor} i={i} />
      ))}
    </div>
  )
}

const VendorAccordion = ({ data, i }) => {
  return (
    <Accordion key={data.vendorName}>
      <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div id={data.id} />
          <span
            style={{
              display: 'inline-block',
              width: 64,
              height: 64,
              borderRadius: '50%',
              overflow: 'hidden',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              marginRight: '1rem',
            }}
          >
            <img
              src={data.imageURL}
              alt={data.vendorName}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </span>
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
          >
            {data.vendorName}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          sx={{
            textAlign: 'left',
            opacity: 0.8,
          }}
        >
          Offer: {data.description}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}
