import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Typography } from '@mui/material'
import { AdvancedMarker } from '@vis.gl/react-google-maps'
import React, { useState } from 'react'

export const CustomAdvancedMarker = ({ vendor }) => {
  const [hovered, setHovered] = useState(false)

  const position = {
    lat: vendor.latitude,
    lng: vendor.longitude,
  }

  return (
    <AdvancedMarker
      position={position}
      title={vendor.vendorName}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        const element = document.getElementById(vendor.id)
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        const parent = element.parentElement.parentElement.parentElement
        parent.click()
      }}
      zIndex={hovered ? 5 : 1}
    >
      {/* Custom Pin */}
      <Box
        sx={{
          position: 'relative',
          width: hovered ? 50 : 34,
          height: hovered ? 50 : 34,
          maxWidth: '100%',
          backgroundColor: '#004e92',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'all 0.2s ease-in-out',
          cursor: 'pointer',
        }}
      >
        {/* Image Container */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            backgroundImage: `url(${vendor.imageURL})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: 'inherit',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'opacity 0.2s ease-in-out',
          }}
        />
      </Box>
    </AdvancedMarker>
  )
}
